var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"demo-data",style:({
    width: '100%',
    backgroundSize: 'cover',
    height: _vm.computedHeight + 'px'
  })},[_c('div',{staticClass:"blur-bacground",style:({
    background: 'url('+ _vm.backroundLink +')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    filter: 'blur(1px)',
    width: '100%',
    height: _vm.computedHeight + 'px',
    position: 'absolute'
  })}),_c('v-card',{staticClass:"information-card",attrs:{"max-width":"444"}},[_c('div',{staticClass:"alert-icon",style:({ backgroundColor: 'red'})},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-alert-circle-outline ")])],1),_c('v-card-text',[_c('b',{staticClass:"text-h6",style:({color: 'red',})},[_vm._v(" Это демо-данные ")]),_c('div',{style:({color: 'red'})},[_vm._v(" Оплатите проект, чтобы начать пользоваться функциями ")])]),_c('v-card-actions',[_c('v-btn',{staticClass:"billing-btn",attrs:{"variant":"flat","color":"red"},on:{"click":_vm.redirectBilling}},[_vm._v(" ОПЛАТИТЬ ПРОЕКТ ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }