<template>
    <div class="demo-data"  
      :style="{
        width: '100%',
        backgroundSize: 'cover',
        height: computedHeight + 'px'
      }"
    >
    <div class="blur-bacground"
      :style="{
        background: 'url('+ backroundLink +')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        filter: 'blur(1px)',
        width: '100%',
        height: computedHeight + 'px',
        position: 'absolute'
      }">

    </div >
      <v-card class="information-card" max-width="444">
        <div class="alert-icon" :style="{ backgroundColor: 'red'}">
                    <v-icon color="white"> mdi-alert-circle-outline </v-icon>
            </div>
        <v-card-text>
          <b class="text-h6" :style="{color: 'red',}">
            Это демо-данные
          </b>
          <div :style="{color: 'red'}">
            Оплатите проект, чтобы начать пользоваться функциями
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn variant="flat" color="red" @click="redirectBilling" class="billing-btn">
            ОПЛАТИТЬ ПРОЕКТ
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </template>
  
  <script>
  import { useProject } from '@root/src/hooks/useProject';
import { computed, getCurrentInstance, ref } from 'vue';
import useRouter from '@/hooks/useRouter'
  export default {
    name: 'DemoSubView',
    props: {
      backroundLink: String
    },
    setup(props, context) {
      const computedHeight = ref(400);
      const { project } = useProject()
      const root = getCurrentInstance()?.proxy

      const linkImage = new Image();
      linkImage.src = props.backroundLink;
  
      linkImage.onload = () => {
        computedHeight.value = linkImage.height;
      };

      
        const currentNotification = computed(() => _.first(props.unreadedDageourNotifications))
        const redirectBilling = () => {
            const url = `/project/${project.value.id}/billing?section=tariffs`
            useRouter(root).router.push(url)
        }
  
      return {
        redirectBilling,
        computedHeight
      };
    }
  };
  </script>
  
  <style lang="sass" scoped>
  .demo-data
    display: flex
    align-items: center
    justify-content: center
    position: relattive
    opacity: 10px
  .information-card
    border: 1px solid red
    border-radius: 20px
    padding: 10px 0
    padding-left: 55px
  .billing-btn
    color: white
    margin: 0
  .alert-icon
    width: 30px
    height: 30px
    border-radius: 70%
    position: absolute
    left: 20px
    top: 20px
    display: flex
    justify-content: center
    align-items: center
  </style>